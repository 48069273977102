import React from 'react';
import Layout from "../../components/layout";

const BassendeanDojo = () => {
    return (
        <Layout>
            <h1>New Bassendean Dojo</h1>
            <p>Due to the closure of the Cottesloe Dojo a new Dojo has been opened.</p>
            <p>While initially the new southern perth dojo was opened in Bayswater it has now moved to Bassendean.</p>
            <p>For more details go to the <a href="/locations/Bassendean">Bassendean</a> page.</p>
        </Layout>
    )
}

export default BassendeanDojo;
